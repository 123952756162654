import React, { Component } from 'react';

class Footer extends Component {
    clickedContact = () => {
        window.location.href = "mailto:clubxrpl@gmail.com";
    }

    render() {
        const year = new Date().getFullYear()
        return (
            <footer>
                  <div className="footer-top-wrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-9">
                                <div className="footer-widget">
                                    <div className="footer-logo mb-25">
                                        <a href="index.html"><img src="assets/img/logo/logo.png" alt=""/></a>
                                    </div>
                                    {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus veritatis sequi doloribus fuga.</p> */}
                                    <ul className="footer-social">
                                        <li><a href="https://discord.gg/clubxrpl"><i className="fab fa-discord"></i></a></li>
                                        <li><a href="https://twitter.com/ClubXRPL"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://clubxrpl.gitbook.io"><i className="fab fa-github"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="footer-widget">
                                    <h4 className="fw-title">Useful Links</h4>
                                    <ul className="fw-links">
                                        <li><a href="https://clubxrpl.gitbook.io/club-xrpl-official-info/" target='blank'>Docs</a></li>
                                        <li><a href="buy">How To Buy</a></li>
                                        <li><a href="marketplaces">Marketplaces</a></li>
                                        <li><a href="terms">Terms of Service</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="footer-widget">
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="footer-widget">
                                    <h4 className="fw-title">Contact Us</h4>
                                    <form action="#" className="newsletter-form">
                                        <input type="email" placeholder="clubxrpl@gmail.com"/>
                                        <button onClick={this.clickedContact}><i className="flaticon-small-rocket-ship-silhouette"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="copyright-text">
                                    <p>All rights reserved © 2022 by <a href="#">ClubXRPL</a></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="copyright-link-list">
                                    <li><a href="/terms">Terms of Service</a></li>
                                    <li><a href="mailto:clubxrpl@gmail.com">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}

export default Footer;
