import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import ToggleFullscreen from '../Common/ToggleFullscreen';
// import HeaderRun from './Header.run'

class Header extends Component {

    componentDidMount() {
    }

    clickedClose = e => {
        e.preventDefault();
        $('body').removeClass('mobile-menu-visible');
    }

    clickedNavigator = e => {
        e.preventDefault()
        $('body').addClass('mobile-menu-visible');
    }

    clickedDropDown = e => {
        e.preventDefault()
        $(e.target).toggleClass('open');
        $(e.target).prev('ul').slideToggle(500);
    }

    clickedContact = () => {
        window.location.href = "mailto:clubxrpl@gmail.com";
    }

    render() {
        return (
            <header>
                <div className="menu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler"><i className="fas fa-bars" onClick={this.clickedNavigator}></i></div>
                                <div className="menu-wrap">
                                    <nav className="menu-nav">
                                        <div className="logo"><a href="#"><img src="img/logo/xrprarity.png" alt="" /></a></div>
                                        <div className="header-form">
                                            {/* <form action="#">
                                                <button><i className="flaticon-search"></i></button>
                                                <input type="text" placeholder="Search Artwork"/>
                                            </form> */}
                                        </div>
                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                {/* <li className="active"><a href="index.html">Home</a></li> */}
                                                <li className="menu-item-">
                                                    <a href="introduction/thebearablebulls">Genesis NFT</a>
                                                </li>
                                                <li className="menu-item-has-children"><div>Curated</div>
                                                    <ul className="submenu">
                                                        <li><a href="introduction/therichducks">The Rich Ducks</a></li>
                                                        <li><a href="introduction/clubxbaes">Club X Baes</a></li>
                                                        <li><a href="introduction/phoenixeggs">Phoenix Eggs</a></li>
                                                        <li><a href="introduction/babyaperocketbrigade">Baby Ape Rocket Brigade</a></li>
                                                        <li><a href="https://secretmarket.xyz/collection/bearableguyclub-genesis-i" target='blank'>BearableguyClub</a></li>
                                                        <li><a href="https://secretmarket.xyz/collection/punkworlds" target='blank'>PunkWorldsArt</a></li>
                                                    </ul>
                                                </li>
                                                {/* <li className="menu-item-has-children"><div>Extras</div>
                                                    <ul className="submenu">
                                                        <li><a href="ranking">XRPL NFT Rarity</a></li>
                                                        <li><a href="staking">NFT Staking</a></li>
                                                        <li><a href="https://discord.gg/csAg3zwTvy" target='blank'>NFT Auctions</a></li>
                                                        <li><a href="marketplaces" target='blank'>Marketplaces</a></li>
                                                        <li><a href="https://xrpl.to/" target='blank'>Token Tracker</a></li>
                                                        <li><a href="https://degendesigns.net/" target='blank'>Merch</a></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item-has-children"><div>VIP</div>
                                                    <ul className="submenu">
                                                        <li><a href="nftview">NFT Viewer/Vault wallet</a></li>
                                                        <li><a href="scanner">Marketplace Scanner</a></li>
                                                    </ul>
                                                </li> */}
                                                <li className="menu-item">
                                                    <button onClick={this.clickedContact}>Launchpad</button>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="header-action d-none d-md-block">
                                            <ul>
                                                <li className="header-btn"><a href="#" className="btn">Wallet Connect</a></li>
                                            </ul>
                                        </div> */}
                                    </nav>
                                </div>
                                {/* <!-- Mobile Menu  --> */}
                                <div className="mobile-menu">
                                    <nav className="menu-box">
                                        <div className="close-btn"><i className="fas fa-times" onClick={this.clickedClose}></i></div>
                                        <div className="nav-logo"><a href="index.html"><img src="img/logo/logo.png" alt="" /></a>
                                        </div>
                                        <div className="menu-outer">
                                            <ul className="navigation">
                                                <li className="menu-item">
                                                    <a href="introduction/thebearablebulls">Genesis NFT</a>
                                                </li>
                                                <li className="menu-item-has-children"><div className="menu-item-title">Curated</div>
                                                    <ul className="submenu">
                                                        <li><a href="https://bearableguy.club" target='blank'>BearableguyClub</a></li>
                                                        <li><a href="https://punkworlds.art" target='blank'>PunkWorldsArt</a></li>
                                                        <li><a href="introduction/therichducks">The Rich Ducks</a></li>
                                                        <li><a href="introduction/clubxbaes">Club X Baes</a></li>
                                                        <li><a href="introduction/phoenixeggs">Phoenix Eggs</a></li>
                                                        <li><a href="introduction/babyaperocketbrigade">Baby Ape Rocket Brigade</a></li>
                                                    </ul>
                                                    <div className="dropdown-btn" onClick={this.clickedDropDown}><span className="fas fa-angle-down"></span></div>
                                                </li>
                                                {/* <li className="menu-item-has-children"><div className="menu-item-title">Extras</div>
                                                    <ul className="submenu">
                                                        <li><a href="ranking">XRPL NFT Rarity</a></li>
                                                        <li><a href="staking">NFT Staking</a></li>
                                                        <li><a href="https://discord.gg/csAg3zwTvy" target='blank'>NFT Auctions</a></li>
                                                        <li><a href="marketplaces" target='blank'>Marketplaces</a></li>
                                                        <li><a href="https://xrpl.to/" target='blank'>Token Tracker</a></li>
                                                        <li><a href="https://degendesigns.net/" target='blank'>Merch</a></li>
                                                    </ul>
                                                    <div className="dropdown-btn" onClick={this.clickedDropDown}><span className="fas fa-angle-down"></span></div>
                                                </li>
                                                <li className="menu-item-has-children"><div className="menu-item-title">VIP</div>
                                                    <ul className="submenu">
                                                        <li><a href="nftview">NFT Viewer/Vault wallet</a></li>
                                                        <li><a href="scanner">Marketplace Scanner</a></li>
                                                    </ul>
                                                    <div className="dropdown-btn" onClick={this.clickedDropDown}><span className="fas fa-angle-down"></span></div>
                                                </li> */}
                                                <li className="menu-item">
                                                    <button onClick={this.clickedContact}>Launchpad</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                                <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                                <li><a href="#"><span className="fab fa-pinterest-p"></span></a></li>
                                                <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                                <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop"></div>
                                {/* <!-- End Mobile Menu --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);