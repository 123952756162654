/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.8.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
// import "./Vendor";

// Application Styles
import './styles/bootstrap.min.css'
import './styles/animate.min.css';
import './styles/magnific-popup.css'
import './styles/fontawesome-all.min.css'
import './styles/uicons-solid-rounded.css'
import './styles/jquery.mCustomScrollbar.min.css'
import './styles/flaticon.css'
import './styles/slick.css'
import './styles/default.css'
import './styles/style.css'
import './styles/responsive.css'


class App extends Component {
  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');

    return (
        <BrowserRouter basename={basename}>
            <Routes />
        </BrowserRouter>
    );
  }
}

export default App;
